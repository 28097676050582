<template>
  <vue-cal
    today-button
    locale="zh-hk"
    class="vuecal--blue-theme"
    :time-from="9 * 60"
    :time-to="24 * 60"
    :disable-views="['years', 'year', 'month']"
    :events="events"
    hide-view-selector="hide-view-selector"
    @ready="fetchEvents"
    @view-change="fetchEvents"
    :on-event-click="onEventClick"
  >
    <template #event="{ event }">
      <div class="vuecal__event-title" v-html="event.title"></div>
    </template>
  </vue-cal>
</template>
<script>
// import Storage from "../../../models/storage";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  data() {
    return {
      selectedEvent: {},
      selectedDate: new Date(),
      events: [],
      sd: "",
      ed: "",
    };
  },
  props: {
    teacherId: {
      type: Number,
    },
    courseId: {
      type: Number,
    },
    teacherMemberId: {
      type: Number,
    },
  },
  components: {
    VueCal,
  },
  watch: {
    teacherId() {
      this.GetData(this.sd, this.ed);
    },
  },
  methods: {
    fetchEvents({ view, startDate, endDate, week }) {
      const vm = this;
      vm.sd = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      vm.ed = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      console.log(view, week);
      vm.GetData(vm.sd, vm.ed);
    },
    GetData(sd, ed) {
      const vm = this;
      // console.log(vm.teacherId);
      // console.log("課程Id", vm.courseId);
      if (vm.teacherId != "" && vm.teacherId != undefined) {
        vm.fetchAPI(
          "get",
          `${process.env.VUE_APP_URL_API}/teacher/${vm.teacherId}/calendar?startDate=${sd}&endDate=${ed}`
        ).then((res) => {
          vm.events = res.data;
          // console.log(vm.events);
          if (vm.courseId != undefined) {
            vm.events = vm.events.filter((x) => x.id == vm.courseId);
          }
        });
      }
    },
    onEventClick(event, e) {
      this.selectedEvent = event;
      //如果在老師頁面
      if (this.$route.path.includes("TeacherIntroduce")) {
        this.$router.push(`/ChargeMeTribe/Class/${this.selectedEvent.id}`);
      } else {
        this.$confirm("要向老師傳送課程訊息嗎?", "詢問課程資訊", {
          confirmButtonText: "傳送",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.state.askCourseId = this.selectedEvent.id;
            this.sendMessage();
          })
          .catch((er) => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            console.log(er);
          });
      }

      e.stopPropagation();
    },
    sendMessage() {
      let vm = this;
      vm.$store.commit("OpenMessageWin");
      vm.$store.commit("CreateMessageGroup", vm.teacherMemberId);
      vm.$store.state.askCourse = true;
    },
  },
};
</script>

<style>
.vuecal__event {
  cursor: pointer;
}

.vuecal__event-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 4px 0 8px;
}

.vuecal__event-time {
  display: inline-block;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.vuecal__event-content {
  font-style: italic;
}
</style>
